<template>
	<div class="mobile-announce-content">
        <header>
            <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
        </header>
		<crumbs :item="title"></crumbs>
        <div class="main-content">
            <div class="wrap">
                <div class="box" v-for="(item, index) in contentIntro" :key="index">
                    <!-- <p>{{ item.title }}</p> -->
                    <p v-html="item.title"></p>
                    <table
                        border="1px solid #ccc"
                        cellspacing="0"
                        cellpadding="0"
                        class="table_style"
                    >
                        <tbody>
                            <tr>
                                <td colspan="5">◎每月宣告利率</td>
                            </tr>
                            <tr>
                                <td colspan="5">
                                    <span style="color: #003781">公布日:&nbsp;&nbsp;</span
                                    ><span>{{ announceTime }} </span>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>最低保证年利率</td>
                                <td>月初浮动年利率</td>
                                <td>月结算年利率</td>
                                <td>月结算日利率</td>
                            </tr>
                            <tr v-for="(rateitem, rateindex) in item.rate" :key="rateindex">
                                <td>{{ rateitem.TERM }}</td>
                                <td>{{ rateitem.GRNT_RATE }}</td>
                                <td>{{ rateitem.FLOAT_RATE }}</td>
                                <td>{{ rateitem.INV_RATE }}</td>
                                <td>{{ rateitem.DAY_RATE }}</td>
                            </tr>
                            <!-- ◎保单持续奖金 -->
                            <tr
                                v-if="
                                    item.code == 'AYTL43' ||
                                    item.code == 'SSWY43' ||
                                    item.code == 'AYHL44' ||
                                    item.code == 'AYHL43'
                                "
                            >
                                <td colspan="2">◎保单持续奖金</td>
                                <td colspan="3">{{ item.contPolicy }}</td>
                            </tr>
                            <!-- ◎持续奖金率 -->
                            <tr
                                v-if="
                                    item.code == 'YYLC28' ||
                                    item.code == 'JYSJ13' ||
                                    item.code == 'FYRS13' ||
                                    item.code == 'LHLC13' ||
                                    item.code == 'DYJ13'
                                "
                            >
                                <td colspan="2">◎持续奖金率</td>
                                <td colspan="3">{{ item.contPolicyRate }}</td>
                            </tr>
                            <!-- ◎当时市场利率 -->
                            <tr
                                v-if="
                                    item.code == 'YYLC28' ||
                                    item.code == 'JYSJ13' ||
                                    item.code == 'FYRS13' ||
                                    item.code == 'LHLC13' ||
                                    item.code == 'DYJ13'
                                "
                            >
                                <td colspan="2">◎当时市场利率</td>
                                <td colspan="3">{{ item.currentRate }}</td>
                            </tr>
                            <tr>
                                <td
                                    colspan="5"
                                    style="text-align: left; padding: 10px"
                                    v-html="item.text"
                                ></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="historyBtn" @click="history(item.code)">历史利率查询</div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="qrcode">
            <div class="item">
                <img src="~@/static/images/public.jpg" alt="" />
                <span>公众号：安联人寿</span>
            </div>
            <div class="item">
                <img src="~@/static/images/service.jpg" alt="" />
                <span>服务号-安联在线</span>
            </div>
            </div>
            <div class="info">
            <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
            <p class="beian">沪ICP备07011309号-1</p>
            </div>
        </div>
	</div>
</template>

<script>
	import crumbs from "@/components/crumbs.vue";
    import $ from "jquery";
    import common from '@/utils/common'
	export default {
		name: "announcementMobile",
        props: {
            contentIntro: {//每个tab的数据
                type: Array,
            },
            announceTime: {
                type: String,//公布日
            }
        },
		data() {
			return {
				title: [
					{
						title: "新闻公告",
						link: "",
					},
					{
						title: "万能险利率公告",
						link: "/announcement",
					},
				],
			};
		},
		components: {
			crumbs,
		},
		methods: {
			//跳转到详细页面
			history(covercode) {
				this.$router.push({
					name: "interestrateHistory",
					params: {
						countType: covercode,
					},
				});
                localStorage.setItem("interestrateHistoryCovercode", covercode);
			},
		},
		created() {
            this.isMobile = window.navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            var isPad = common.isPad();
			if(isPad){
				this.isMobile = false
			}
            console.log(this.isMobile);
            if (this.isMobile && this.id =="AZ022") {
                $("body").css("overflow", "hidden");
            }
		},
	};
</script>
<style lang='scss' scoped>
    @function px2rem($px, $screen: 1920) {
        @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
    }

    @function px2vw($px) {
        @return calc(100vw * #{$px} / 1920)
    }
    .mobile-announce-content {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: #fff;
        height: 100vh;
        width: 100vw;
        padding-bottom: px2rem(30, 375);

        header {
            width: 100%;
            height: px2rem(50, 375);
            display: flex;
            align-items: center;
            padding-left: px2rem(15, 375);
            img {
                width: px2rem(75, 375);
                height: px2rem(30, 375);
            }
        }
        .main-content {
            width: 100%;
            padding: px2rem(10, 375) px2rem(10, 375);
            padding-bottom: px2rem(120, 375);
            height: 100%;
            overflow-y: scroll;
            .wrap {
                width: 100%;
                padding: 0;
                .box {
                    /* width: 55%; */
                    margin: auto;
                    padding: px2rem(10, 375) 0;
                    //height: 80vw;
                    //border: 1px solid;
                    p {
                        font-size: px2rem(18, 375);
                        color: #003781;
                        /* width: 70%; */
                        margin: 0rem auto px2rem(10, 375);
                        text-align: center;
                    }
                }
            }
            .table_style {
                border-collapse: collapse;
                width: 100%;

                tr {
                    height: 3.5rem;

                    td {
                        font-family: 方正正中黑简体;
                        color: #3e3e3e;
                        font-size: px2rem(10, 375);
                        width: 20%;
                        text-align: center;
                        border: 1px solid #3e3e3e;
                        font-weight: 400;
                    }
                    > td:nth-child(1) {
                        width: 10%;
                    }
                }
            }
            .historyBtn {
                font-size: px2rem(14, 375);
                color: #72007c;
                padding-top: px2rem(10, 375);
                width: 80%;
                cursor: pointer;
            }
            .time {
                font-size: px2rem(12, 375);
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-bottom: px2rem(20, 375);
                text-align: right;
            }
        }
        .bottom {
            width: 100%;
            background-color: #fff;
            padding: px2rem(10, 375) 0;
            position: absolute;
            bottom: 0;
            z-index: 9999;
            .qrcode {
                display: flex;
                justify-content: space-evenly;
                margin: px2rem(30, 375) 0;
                display: none;

            .item {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                img {
                    width: px2rem(100, 375);
                    height: px2rem(100, 375);
                }
                span {
                    font-size: px2rem(13, 375);
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    }
                }
            }

            .info {
                p {
                    font-size: px2rem(13, 375);
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;

                    &.beian {
                        color: RGBA(38, 77, 139, 1);
                    }
                }
            }
        }
    }
</style>