import http from "@/utils/request.js";

/**
 * @methods 获取表格数据 (5年 10年)
 */
export const getNewAnnouce = (params) => {
	return http({
		url: "/product/getUvlPriceCur",
		method: "post",
		data:params
	});
};

/**
 * @methods 获取表格数据 (持续奖金率)
 */
export const getNewPerstst = (params) => {
	return http({
		url: "/product/getRateInfo",
		method: "get",
		params,
	});
};
